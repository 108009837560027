$bgcolor: #050810;
$secondary-textcolor: #a2acc8;
$darkbtn: #0e1423;
$greencolor: #5e9f9d;
$bgmodal: #10131d;

*, h1, h2 {
    font-family: "Poppins", serif;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    font-weight: 400;
    color: #ffffff;
}
html {
    scroll-behavior: smooth;
}
body {
    font-family: "Poppins", serif;
    font-weight: 400;
}
p {
    margin: 0;
}

.p-light{
    font-family: "Poppins", serif;
    font-weight: 300;
}
.p-regular {
    font-family: "Poppins", serif;
    font-weight: 400;
}
.p-medium {
    font-family: "Poppins", serif;
    font-weight: 500;
}
.p-sbold {
    font-family: "Poppins", serif;
    font-weight: 600;
}

#page-containerr {
    background-color: $bgcolor;
}
hr.section-hr {
    background-image: linear-gradient(to left, #050810, #3b3b3b 49%, #050810);
    margin: 0;
    height: 1px;
}
.rectangle {
    color: #fff;
    border-radius: 4px;
}
.dark-btn {
    background-color: $darkbtn;

    &:hover {
        background-color: #062841a8;
        text-decoration: none;
    }
}
.btn-bordered {
    border: 1px solid #3d4a74;
    background-color: $bgcolor;
}
.world {
    position: absolute;
    right: 0;
    top: 135px;
    width: 81%;
}

.navbar-light .navbar-nav {
    a.nav-link,
    #governance button{
        color: $secondary-textcolor;
        cursor: pointer;

        &:hover, &:focus {
            color: #FFFFFF;
        }
    }
    .nav-link.active {
        color: #FFFFFF;
    }
}
#governance button{
    background-color: transparent;
    border: unset;
    box-shadow: unset;
    margin-top: 2px;
}
#governance .dropdown-menu {
    background-color: #060810;
    border: 1px solid #3b3b3b;
    width: 320px;
}
#governance .dropdown-menu a{
    color: $secondary-textcolor;
    white-space: unset;
    margin-bottom: .5em;
}
#governance .dropdown-menu a:hover{
    transition: ease all .5s;
    color: #fff;
    background-color: transparent;
}
#links #governance button{
    padding: 0;
    margin: 0;
}

.grid-container {
    margin-bottom: 40px;
    display: grid;
    grid-column-gap: 75px;
    grid-template-columns: repeat(auto-fill, minmax(468px, 1fr));
}

#home-section{
    position: relative;

    .headline {
        margin-top: 272px;
        margin-bottom: 20px;
        font-size: 40px;
        line-height: normal;
        letter-spacing: normal;
    }
    h2.description {
        width: 50%;
        line-height: 1.41;
        font-size: 85px;
        margin-bottom: 85px;
    }
    .contact {
        padding: 14px 51px 13px 50px;
    }
    .about {
        padding: 14px 59px 13px;
    }
}

#about-section {
    padding-top: 88px;
    padding-bottom: 88px;
}
.title {
    margin-bottom: 30px;
    font-size: 30px;
}
.subtitle {
    font-size: 40px;
    line-height: 1.5;
}
.secondary-color {
    color: $secondary-textcolor;
}
.text.primary-color {
    font-size: 20px;
    font-style: italic;
    line-height: 1.75;
    margin-bottom: 57px;
}
.text.secondary-color {
    font-size: 16px;
    line-height: 2.5;
}
#mission {
    padding-top: 242px;
    padding-bottom: 313px;
    position: relative;
    overflow: hidden;

    .content {
        margin-left: 30%;
        max-width: 900px;
        position: relative;
    }
    .subtitle {
        margin-bottom: 50px;
    }
    .globe {
        position: absolute;
        top: 0;
        left: 0;
    }
}
#board-section {
    $size: 185px;
    padding: 150px 0;
    position: relative;

    .people {
        position: relative;
        a {
            text-decoration: none;
            color: inherit;
        }
    }
    .chain {
        position: absolute;
        right: 0;
        top: -7em;
    }
    .subtitle {
        max-width: 53%;
        margin-bottom: 70px;
    }
    .img-container {
        width: $size;
        height: $size;
        border-radius: 50%;
        background-size: cover;
    }
    .container-content {
        min-width: $size;
        padding: 30px;
        text-align: center;

        .name {
            font-size: 20px;
            margin-top: 30px;
            margin-bottom: 4px;
        }
    }
}
.img1 {
    background-image: url('../../public/img/Leon.jpeg');
}
.img2 {
    background-image: url('../../public/img/Alan.jpg');
}
.img3 {
    background-image: url('../../public/img/Seb.jpeg');
}
.img4 {
    background-image: url('../../public/img/Sanjay.jpg');
}
.img5 {
    background-image: url('../../public/img/Mark.jpg');
}
.jc {
    justify-content: space-between;
}

#investors-section p.title-column {
    margin-top: 70px;
}

#investors-section, #links {
    padding: 150px 0;

    .subtitle {
        max-width: 50%;
    }
    .address p, .phone-no {
        font-size: 16px;
        line-height: normal;
        font-family: "Poppins", serif;
        font-weight: 300;
    }
    .phone-no {
        margin-top: 30px;
    }
    p.title-column {
        font-size: 25px;
        margin-bottom: 35px;
    }
    .align-content-stretch {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .info-first {
        display: flex;
        flex-direction: column;
    }
}

#news-section {
    padding: 150px 0;
    overflow: hidden;

    .subtitle {
        margin-bottom: 60px;
    }
    .posts.row {
        margin: 0 -38px;
        justify-content: center;
    }
    .d-flex {
        justify-content: space-between;
    }
    .view-more {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 166px;
        height: 50px;
    }
    .view-m {
        display: none;
    }
}

.post {
    padding: 0 38px;
    margin-bottom: 30px;
}
.date {
    font-family: "Poppins", serif;
    font-weight: 300;
    margin-top: 35px;
}
.pagination {
    padding-top: 80px;
}
.img-container {
    height: 244px;
    overflow: hidden;
    border-radius: 10px;
    font-size: 16px;

    img {
        width: 100%;
    }
}
.title-article {
    font-size: 30px;
    margin-top: 16px;
    margin-bottom: 24px;
}

#links {
    padding-bottom: 65px;


    .title-column.transparent {
        visibility: hidden;
    }
    .info  {
        p {
            line-height: 2.19;
            font-size: 16px;
        }
        a {
            color: #FFFFFF;
            text-decoration: none;
            align-items: center;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }
    .social {
        margin-left: 40%;
    }
}

footer {
    background-color: $bgcolor;
}

.home-footer {
    padding: 35px 0 40px;

    p {
        font-size: 16px;
    }
}

section, #content-page {
    background-color: $bgcolor;
}
section#home-section {
    background-color: rgba(0,0,0,0);
}
#content-page {
    padding-bottom: 343px;
}

img.img-homepage {
    padding-left: 3em !important;
}
.row.row-articles {
    padding: 1em;
}
footer {
    position: absolute;
    width: 100%;
}

#contact-form {
    $border: 1px solid #1d202d;
    $bg: #131621;

    .contact-modal {
        padding: 47px 30px 30px;
        width: 608px;
        margin: 0 auto;
        border-radius: 10px;
        background-color: $bgmodal;
    }
    .modal-dialog {
        max-width: inherit;
    }
    .contact-title {
        font-size: 35px;
        margin-bottom: 10px;
    }
    p.secondary-color {
        font-size: 14px;
        line-height: normal;
        margin-bottom: 35px;
    }
    input, .input-group-text {
        height: 60px;
        background-color: $bg;
        color: $secondary-textcolor;
    }
    input {
        border: $border;
        border-left: none;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    input, textarea {
        &::placeholder {
            font-size: 14px;
            color: $secondary-textcolor;
            font-family: "Poppins", serif;
            font-weight: 300;
        }
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            color: $secondary-textcolor;
            -webkit-text-fill-color: $secondary-textcolor;
            background-color: $bg;
            font-size: 14px;
            -webkit-box-shadow: 0 0 0 1000px $bg inset;
            transition: background-color 5000s ease-in-out 0s;
        }
    }
    textarea {
        height: 211px!important;
        resize: none;
        background-color: $bg;
        border: $border;
        color: $secondary-textcolor;
        font-size: 14px;
        border-radius: 10px;
        &::-webkit-scrollbar {
            width: 6px;
            background-color: $bg;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            background-color: $secondary-textcolor;
        }
    }
    .input-group {
        margin-bottom: 16px;
    }
    .form-group {
        margin-bottom: 25px;
    }
    .send-message-btn {
        height: 50px;
        border-radius: 5px;
        color: #FFFFFF;
        background-color: #5e9f9d;
    }
    button.close {
        position: absolute;
        right: 40px;
        top: 40px;
        text-shadow: none;
    }
    .modal-footer, .modal-body{
        padding: 0;
    }
    .input-group-text {
        border: $border;
        border-right: none;
        padding: 18px 20px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        i {
            color: $secondary-textcolor;
        }
    }
    input, textarea, button {
        &:focus {
            box-shadow: none;
        }
    }
}

.navbar-light .navbar-toggler {
    outline: none;
}
.navbar-nav .contact {
    cursor: pointer;
}

ul {
    list-style: none;
    padding: 0;
}
.col-xs-6 {
    max-width: 50%;
}
.navbar {
    height: 100px;
}
.collapse.show, .collapsing {
    position: absolute;
    background-color: #090619;
    top: 100px;
    z-index: 999;
    padding: 10px 50px;
    height: 100vh;
    width: 100vw;
    right: 50%;
    left: 50%;
    transform: translate(-50%, 0);
}
.home-buttons {
    display: flex;
}
.home-buttons a:hover, a.contact:hover {
    text-decoration: none;
    color: #ffffff;
}
.home-buttons a:first-child {
    margin-right: 50px;
}

#all-posts {
    padding: 70px 0 80px 0;
}
a.article:hover {
    text-decoration: none;
}
#news-section .view-all-btn, #all-posts .view-all-btn{
    margin-top: 30px;
}
#news-section .view-all-btn button, #all-posts .view-all-btn button{
    height: 42px;
    border: none;
}
a.col-12 {
    text-align: center;
}
a.col-12:hover {
    text-decoration: none;
}

.table {
    padding: 22px 17px 18px 10px;

    td, th {
        vertical-align: middle;
        font-size: 16px;
        font-family: "Poppins", serif;
        font-weight: 400;
    }
}

#links .info p {
    line-height: 2.19;
    font-size: 16px;
}

#links .info a {
    color: #FFFFFF;
    text-decoration: none;
    align-items: center;
    cursor: pointer;
}

html[lang=de] .lang-options a.s {
    display: none;
}

html[lang=en] .lang-options a:first-of-type {
    display: none;
}

.lang-options a img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.media-table {
    .td-date {
        min-width: 9em;
    }
    td {
        border-top: 1px solid #333641;
        padding: 10px;
    }
    tr:last-child {
        border-bottom: 2px solid #333641;
    }
}

#page-containerr {
    position: relative;
    min-height: 100vh;
}
#content-wrap {
    padding-bottom: 113px;
}

/* all posts */
#all-posts {
    .categories {
        margin-bottom: 30px;

        a {
            color: #ffffff;
            font-size: 16px;
            padding: 9px 25px 10px 27px;
            margin-right: 10px;
            border-radius: 5px;
            border: 1px solid #3d4a74;

            &:hover {
                border: 1px solid transparent;
                background-color: #5e9f9d;
                text-decoration: none;
            }
        }
        .btn-active {
            background-color: #5e9f9d;
        }
        .view-all {
            padding: 9px 37px 10px 39px
        }
        .categories-btns {
            height: 50px;
        }
    }
    .art {
        margin-bottom: 60px;
    }
    .title-top {
        font-size: 40px;
    }
    .title {
        font-size: 50px;
    }
    .view-all-btn button {
        color: #ffffff;
        background-color: #0e1423;
        border-radius: 5px;
        height: 50px;

        &:hover {
            background-color: #062841a8;
        }
    }
}

.date {
    font-size: 16px;
}
.title-article {
    font-size: 30px;
    min-height: 90px;
}
.read-more {
    font-size: 16px;

    &:hover {
        transition: 0.4s;
        text-decoration: underline;
        letter-spacing: 1px;
    }
}


/*-- article-view --*/
#post {
    padding: 100px 0;
    .article-widgets a:hover {
         text-decoration: none;
    }
    .container-article-view {
        max-width: 957px;
        padding: 0 20px 15px 20px;
        margin: 0 auto;
    }
    .img_container {
        height: 424px;
        border-radius: 10px;
        overflow: hidden;
        width: 100%;

        img {
            width: 100%;
            height: auto;
        }
    }
    .full-text-article p {
        line-height: 2.5;
        font-family: "Poppins", serif;
        font-weight: 300;
        color: $secondary-textcolor;
    }
    .title_article {
        margin: 16px 0 50px;
        font-size: 30px;
    }
    .article-widgets {
        display: flex;
        justify-content: center;
        padding-top: 85px;
    }
}
#profile-page {
    padding: 125px 0 181px;

    .container-profile-page {
        padding: 0 158px;
    }
    .profile-widgets {
        display: flex;
        a:hover {
            text-decoration: none;

            .widget-container {
                background-color: #062841a8;;
            }
        }
    }
    .container-image {
        min-width: 450px;
        height: 514px;
        overflow: hidden;
        background-size: cover;
        border-radius: 10px;
        margin-right: 100px;
    }
    .full-name {
        font-size: 35px;
    }
    .full-description {
        margin: 40px 0 163px;
        line-height: 2.5;
    }
}
.widget-container {
    width: 50px;
    height: 50px;
    background-color: #0c1220;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
}
#post button {
    border-radius: 4px;
    background-image: linear-gradient(92deg, #64a7a5, #346e6c);
    border: none;
    height: 42px;
}
.date_categ {
    font-size: 15px;
    line-height: 1.73;
    margin: 9px 0 16px;
}
#footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;

    .widgets-footer {
        margin-left: 50px;
    }
    .footer {
        height: 123px;

        a.contact:hover {
            text-decoration: underline;
        }
    }
}
nav.pagination {
    .dark-btn {
        padding: 14px 22px;
        margin: 5px;

        &.disabled{
            opacity: 0.4;
        }
    }
}

#profile-page .profile-widgets {
    display: none;
}
.widgets-footer {
    display: none!important;
}
#GSCChart {
    width: 100%;
    height: 500px;
}
.chart-container .values-container{
    display: flex;
    align-items: center;
}
.chart-container .values-container > p{
    margin-right: 1em;
}
.chart-container .values-container .main-val{
    font-size: 25px;
}
.chart-container .values-container .asc-desc.green{
    background-color: #162e1e;
    border-radius: 5px;
    padding: 0 0.4em;
}
.chart-container .values-container .asc-desc.green span,
.chart-container .values-container .profit,
.chart-container .values-container .period{
    color: #26A145;
}
.time-details p.timezone{
    font-size: .75rem;
    font-weight: 400;
    line-height: 1rem;
    color: #5f6368;
}
.chart-container .period-select{
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}
.chart-container .period-select a{
    text-decoration: none;
    color: #ececec;
    margin-right: 1.5em;
}
.chart-container .period-select a:hover{
    color: #1a73e8;
    transition: ease all .5s;
}
.chart-container .period-select a.active{
    color: #1a73e8;
}
#chartcontrols {
    height: auto;
    padding: 5px 45px 0 15px;
}
#chartcontrols .am5stock-control-button{
    border: unset
}
#chartcontrols .am5stock-control-button a{
    cursor: pointer;
    color: #fff;
    transition: ease all .5s;
}
#chartcontrols .am5stock-link.active,
#chartcontrols .am5stock-link:hover{
    background: unset;
    color: #5e9f9d;
    text-decoration: underline;
}
p.mt-3 i{
    color: #1a73e8;
    display: block;
    word-break: break-word;
}
.chart-container p.mt-5{
    font-size: 18px;
}
.chart-container p.mt-5 a{
    display: block;
    text-decoration: none;
    word-break: break-word;
}

.mobile-lang{
    display: none;
}

/* media */
/*--- min ---*/
@media (min-width: 1200px) {
    .container {
        max-width: 1556px;
        padding-right: 60px;
        padding-left: 60px;
    }
}

@media (min-width: 1620px) {
    .container {
        max-width: 1556px;
        padding: 0;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 25px;
        padding-left: 25px;
    }
}
@media (min-width: 2000px) {
    .world {
        right: 10%;
        top: 250px;
        width: 60%;
    }
}

@media (min-width: 991px) and (max-width: 1200px) {
    .nav-item.media-none {
        display: none;
    }
    #news-section .posts.row {
        margin: 0 -135px;
    }
}

@media (min-width: 991px) and (max-width: 1330px) {
    #board-section .container-content {
        padding: 30px 70px;
    }
}

/*--- max ---*/
@media (max-width: 1500px) {
    #investors-section p.title-column {
        min-height: 80px;
    }
}

@media (max-width: 1400px) {
    #home-section h2.description {
        width: 60%;
        font-size: 4em;
    }
    .jc {
        justify-content: center;
    }
    .grid-container {
        grid-column-gap: 30px;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    }
    #profile-page .container-image {
        min-width: 390px;
        height: 420px;
        margin-right: 90px;
    }
}

@media (max-width: 1200px) {
    #about-section .image-second {
        width: 80%;
        margin-left: auto;
    }
    #investors-section, #links {
        p.title-column {
            font-size: 22px;
        }
    }
    #profile-page .container-profile-page {
         padding: 0 30px;
    }
    #post .img_container {
        height: auto;
    }
    #links .social {
        margin-left: 30%;
    }
}

@media (max-width: 991px) {
    .mobile-lang{
        display: block;
    }
    #home-section .headline {
        margin-top: 0;
    }
    #content-page {
        padding-bottom: 0;
    }
    section#home-section {
        padding: 5em 1em;
    }
    #investors-section p.title-column {
        min-height: inherit;
    }
    #board-section {
        .container {
            position: relative;
        }
        .chain {
            opacity: 0.4;
        }
    }
    #all-posts {
        .title-top {
            font-size: 30px;
        }
        .title {
            font-size: 40px;
        }
        .categories a {
            font-size: 14px;
        }
    }
    .date, .read-more {
        font-size: 14px;
    }
    .title-article {
        font-size: 25px;
        min-height: inherit;
    }
    .world {
        opacity: 0.4;
    }
    #home-section {
        text-align: center;

        h2.description {
            font-size: 2.4em;
            width: 100%;
        }
        .home-buttons {
            justify-content: center;
        }
    }
    #about-section {
        padding-bottom: 150px;
        text-align: center;

        .descr-text {
            margin-top: 100px;
        }
    }
    .title {
        font-size: 25px;
        text-align: center;
    }
    .subtitle {
        font-size: 35px;
        line-height: 1.3;
        text-align: center;
    }
    .text.primary-color {
        font-size: 18px;
        margin-bottom: 30px;
    }
    .text.secondary-color {
        font-size: 15px;
        line-height: 2.4;
    }
    #mission {
        text-align: center;
        padding: 100px 0;

        .globe {
            opacity: 0.4;
        }
        .content {
            margin-left: 0;
        }
    }
    #board-section,
    #investors-section{
        .subtitle {
            max-width: 100%;
        }
    }
    #news-section {
        .view-more {
            display: none;
        }
        .view-m {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80%;
            margin: 50px auto 0 auto;
            height: 50px;
        }
        .d-flex {
            justify-content: center;
        }
    }
    .navigation_links {
        button i.fa-bars {
            color: #4a8886;
            font-size: 30px;
        }
    }
    .navbar-nav.mr-auto .row {
        flex-direction: column;
        align-items: center;
    }
    .pad {
        padding: 0 0 20px 0 !important;
    }
    #about-section, #investors-section {
        position: relative;
    }
    .text-photo img {
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        opacity: 0.1;
    }
    .text {
        max-width: 100%;
        width: 100%;
    }
    #profile-page {
        padding: 110px 0;
        .d-flex {
            flex-direction: column;
        }
        .container-image {
            min-width: 320px;
            max-width: 320px;
            height: 340px;
            margin-right: 0;
        }
        .full-description {
            margin-bottom: 40px;
        }
    }
    .footer {
        justify-content: center!important;
    }
    #post {
        p.title_article {
            font-size: 30px;
        }
        .container-article-view {
            padding: 0 10%;
        }
    }
    #links {
        .social {
            margin-left: 0;
        }
        .column:not(:last-child) {
            margin-bottom: 60px;
        }
        .title-column.transparent {
            display: none;
        }
        .media-links {
            padding-left: 40px;
        }
    }
    .home-footer {
        text-align: center;
    }
}

@media (max-width: 768px) {
    #investors-section {
        .row {
            text-align: center;
        }
        .align-content-stretch {
            align-items: center;
        }
        .phone-no {
            margin-top: 15px;
        }
        .address p {
            font-size: 15px;
        }
        p.title-column {
            font-size: 20px;
            margin-bottom: 20px;
        }
    }
    .world {
        width: 100%;
        opacity: 0.3;
    }
    #home-section .headline {
        font-size: 40px;
        margin-top: 0;
    }
    #about-section, #board-section, #investors-section, #news-section, #all-posts {
        padding: 80px 0;
    }
    footer {
        padding: 20px 10px;
    }
    .img_container {
        width: 100%;
        height: 200px;
    }
    #post p.title_article {
        font-size: 30px;
    }
    #content-wrap {
        padding-bottom: 170px;
    }
    #profile-page .full-description {
        padding: 100px 0 100px;
    }
    #links {
        .column {
            text-align: center;
        }
        .media-links {
            padding-left: 0;
        }
        .center-info-links .d-flex {
            justify-content: center;
        }
    }
    #links, #investors-section {
        p.title-column {
            margin-bottom: 10px;
        }
    }
    nav.pagination .dark-btn {
        padding: 8px 12px;
    }
}

@media(max-width: 575px) {
    #governance .dropdown-menu {
        width: 250px;
        position: absolute!important;
    }
    #governance .dropdown-menu a{
        font-size: 13px;
    }
    #all-posts{
        .title-top {
            font-size: 20px;
        }
        .title {
            font-size: 40px;
        }
    }
    .text.secondary-color {
        font-size: 14px;
        line-height: 2;
    }
    .subtitle {
        font-size: 30px;
    }
    .title {
        font-size: 22px;
    }
    #about-section,
    #board-section,
    #investors-section,
    #news-section,
    #all-posts {
        padding: 65px 0;
    }
    #post p.title_article {
        font-size: 24px;
    }
    .full-text-article p {
        line-height: 1.5;
    }
    .container {
        padding-left: 25px;
        padding-right: 25px;
    }
    #contact-form {
        .contact-modal {
            height: 100%;
            width: 100%;
            margin: 0 auto;
            border-radius: 0;
            padding: 30px 15px;
        }
    }
    .modal-dialog {
        margin: 0;
    }
    .d-sm-none {
        display: none;
    }
    .footer {
        .container-flex {
            flex-direction: column;
        }
        .widgets-footer {
            margin: 20px auto 0 auto;
        }
    }
    .img-container {
        height: 170px;
    }
    #home-section {
        .headline {
            font-size: 2em;
        }
    }
    .chart-container .values-container {
        display: block;
    }
    .chart-container .values-container .asc-desc.green {
        width: max-content;
    }
}

@media(max-width: 583px) {
    .grid-container {
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }
}

@media(max-width: 425px) {
    #investors-section ul li {
        line-height: 1.5;
    }
    .copyright {
        font-size: 14px;
    }
    .title-article {
        font-size: 20px;
    }
    #all-posts .categories a {
        font-size: 12px;
    }
    #profile-page {
        .container-image {
            min-width: 290px;
            max-width: 290px;
            height: 310px;
        }
    }
    .home-buttons {
        flex-direction: column;

        a, a:first-child {
            margin: 0 3% 50px;
        }
    }
    #content-page.bg-gradient {
        height: 795px;
    }
}
